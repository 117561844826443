import React from "react";
import ReactDOM from "react-dom";
import Map from "./map";
import mapboxgl from "mapbox-gl";

window.addEventListener("load", () => {
  mapboxgl.accessToken         = window.mapboxToken;
  const mapboxContainer        = document.getElementById("mapbox");
  const templateRdvCenters     = document.querySelector('.page-template-rdv-centers');
  const templateCenters        = document.querySelector('.page-template-centers');
  const templatePartnerCenters = document.querySelector('.page-template-partner-centers');
  var getMap = async function() {
    ReactDOM.render(
        <React.StrictMode>
          <Map />
        </React.StrictMode>,
        mapboxContainer
    );
  };

  if (mapboxContainer) {
    if (!templateRdvCenters && !templatePartnerCenters && !templateCenters) {

      const mapObserver = new IntersectionObserver((entries, observer) => {
        // If intersectionRatio is 0, the target is out of view
        // and we do not need to do anything.
        if (entries[0].intersectionRatio <= 0) return;
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            getMap();
            observer.unobserve(entry.target);
          }
        });
      });
      // start observing
      mapObserver.observe(document.querySelector(".map-content"));
    }
    else {
      getMap();
    }
  }
});