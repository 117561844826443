import React, {useState} from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import regions from "./regions";
import * as turf from "@turf/turf";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

function Map() {
    const mapContainer = React.useRef(null);
    const maxCenter = 3;
    const defaultMotif = document.getElementById("mapbox").dataset.defaultMotif;

    const [map, setMap] = React.useState(null);
    const [filteredPVCenters, setFilteredPVCenters] = React.useState({})
    const [filterAge] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])
    const [chosenCenter, setChosenCenter] = React.useState({});
    const [limitedCenter, setLimitedCenter] = React.useState(null);
    const [selectedCenterId, setSelectedCenterId] = React.useState(null);
    const [bounds, setBounds] = React.useState(null);

    const [centerExist, setCenterExist] = React.useState(true);

    const [selectedRegion, setSelectedRegion] = React.useState("none");
    const [selectedReason, setSelectedReason] = React.useState("none");
    const [selectedReasonId, setSelectedReasonId] = React.useState(defaultMotif);
    const [currentCoord, setCurrentCoord] = React.useState("none");

    const [items, setItems] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(maxCenter);

    const [appState, setAppState] = useState({
        centers: [],
    });

    const [appPatterns, setAppPatterns] = useState({
        patterns: [],
    });

    const isCentersTemplate =
        document.querySelector("body.page-template-centers") == null ? false : true;

    const isPartnerCentersTemplate =
        document.querySelector("body.page-template-partner-centers") == null ? false : true;

    const isRDVCentersTemplate =
        document.querySelector("body.page-template-rdv-centers") == null
            ? false
            : true;

    const isPartnerTemplate =
        document.querySelector("body.partner") == null
            ? false
            : true;

    const query = location.search.substring(1);
    let param = "";

    if (query) {
        param = JSON
            .parse('{"' + query.replace(/&/g, '","')
                .replace(/=/g, '":"') + '"}',
                function (key, value) {
                    return key === "" ? value : decodeURIComponent(value)
                })
    }

    React.useEffect(() => {
        const blog_id = document.getElementById("mapbox").dataset.blogId;
        const center_name = document.getElementById("mapbox").dataset.centerName;
        let url = "";
        if (blog_id !== "1") {
            url = "/" + center_name + "/wp-json/wp/v2/patterns/";
        } else {
            url = window.location.origin + "/wp-json/wp/v2/patterns/";
        }

        var geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: "Entrer une adresse",
            marker: false,
            countries: "FR"
        });

        if (isRDVCentersTemplate) {
            document.getElementById('geo-search').appendChild(geocoder.onAdd(map));
        }

        if (query && isRDVCentersTemplate) {
            const selectAge = document.getElementById("selectAge");
            const txtSearch = document.getElementsByClassName("mapboxgl-ctrl-geocoder--input");

            if (txtSearch) {
                txtSearch[0].value = param.search;
            }

            if (selectAge) {
                selectAge.value = param.age;
            }

            selectAge.disabled = param.age === "0";
        }
        fetch(url)
            .then((res) => res.json())
            .then((myJSON) => {
                let patterns = Object.values(myJSON)[2];
                let tab = [];

                for (let i = 0; i < patterns.length; i++) {
                    let currentPatterns = {};

                    currentPatterns.id = patterns[i].id_alaxione;
                    currentPatterns.default = patterns[i].is_default;
                    currentPatterns.label = patterns[i].libelle;
                    currentPatterns.age_restriction = patterns[i].age_restriction;

                    if (query && param.reason === patterns[i].libelle) {
                        setSelectedReasonId(patterns[i].id_alaxione);
                        setSelectedReason(patterns[i].libelle);
                    }

                    tab.push(currentPatterns);
                }

                let coordinates = "none";
		        if (typeof param.search == "string"){
		          coordinates = getCoordinates(param.search);
		
                  coordinates.then(value => {
                    setCurrentCoord(value);
                  });
		        }

                setAppPatterns({patterns: tab});
            });
    }, []);


  const getCoordinates = (search) => {
    let data = [];
    let result = [];
    let coordinate_array = null;

    if (search && typeof search == "string") {
      const url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + search + ".json?access_token=" + mapboxgl.accessToken;

      return fetch(url)
        .then(async (response) => {
          data.json = await response.json();

          if (data['json']['features']) {
            if (data['json']['features'].length > 0) {
              result.push(data['json']['features'][0].center);
            }
          }

          if (result.length > 0) {
            coordinate_array = result[0];
          }

          return coordinate_array;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
      }
      return null;
   };



    React.useEffect(() => {
        const blog_id      = document.getElementById("mapbox").dataset.blogId;
        const center_name  = document.getElementById("mapbox").dataset.centerName;
        const code_partner = document.getElementById("mapbox").dataset.codePartner;
        let url = "";

        console.log(currentCoord);
        console.log(selectedReasonId);

        if (isCentersTemplate) {
            url = "/wp-json/wp/v2/centers/";
        }
        else if (currentCoord !== "none" && isRDVCentersTemplate) {
            url = `/wp-json/wp/v2/centers/${currentCoord[0].toFixed(2)}-${currentCoord[1].toFixed(2)}/${selectedReasonId}/`;
        }
        else if (isPartnerCentersTemplate) {
            url = `/wp-json/wp/v2/partner_centers/${code_partner}/`;
        }
        else if (isPartnerTemplate) {
            url = `/${center_name}/wp-json/wp/v2/nearby_centers/${blog_id}/${code_partner}/`;
        } else if (!isPartnerTemplate && blog_id !== "1") {
            url = `/${center_name}/wp-json/wp/v2/nearby_centers/${blog_id}/`;
        } else if (blog_id === "1") {
            url = `${window.location.origin}/wp-json/wp/v2/nearby_centers/${blog_id}/`;
        } else {
            url = `${window.location.origin + window.location.pathname}wp-json/wp/v2/nearby_centers/${blog_id}/`;
        }

        console.log(url);

        fetch(url)
            .then((res) => res.json())
            .then((myJSON) => {
                let acfFields = Object.values(myJSON)[2];

                if ( acfFields !== undefined ) {
                    acfFields.sort((a, b) => {
                        a = a.name || "";
                        b = b.name || "";
                        return a.localeCompare(b);
                    });
                }

                let tabs = [];
                let items = [];

                if ( acfFields !== undefined ) {
                    for (let i = 0; i < acfFields.length; i++) {

                        if ( acfFields[i].name !== "Groupe Point Vision" && acfFields[i].name !== "partenaires" ) {
                            let currentCenter = {};
                            currentCenter.id = acfFields[i].id;
                            currentCenter.id_alax = acfFields[i].id_alaxione;
                            currentCenter.name = acfFields[i].name;
                            currentCenter.slug = acfFields[i].slug;
                            currentCenter.theme = acfFields[i].theme;
                            currentCenter.patterns = acfFields[i].patterns;

                            currentCenter.lng = acfFields[i].map_lng;
                            currentCenter.lat = acfFields[i].map_lat;

                            currentCenter.address = acfFields[i].address;
                            currentCenter.region = acfFields[i].region;
                            currentCenter.phone = acfFields[i].phone;
                            currentCenter.first_appoint = acfFields[i].first_appoint;
                            currentCenter.first_appoint_format = acfFields[i].first_appoint_format;

                            if (!acfFields[i].name) {
                                currentCenter.name = acfFields[i].slug;
                            }

                            if (isRDVCentersTemplate) {
                                if (i < currentPage) {
                                    tabs.push(currentCenter);
                                }
                            } else {
                                tabs.push(currentCenter);
                            }
                            items.push(currentCenter);
                        }

                    }
                }

                setItems(items);
                setLimitedCenter(tabs);

                setAppState({centers: items});
            });
    }, [selectedReasonId, currentCoord]);

    React.useEffect(() => {
	let map;
	map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v10",
	});

	if (currentCoord!="none"){
          map.setCenter(currentCoord).setZoom(10);
	}
	else{
	  map.setCenter([2.85, 46.93]).setZoom(4.8);
	}

    map.on("moveend", function () {
        setBounds(map.getBounds());
    });

    const pvCentersById = {};

    appState.centers.forEach(function (pvCenter, key) {
        let url_default_appoint = getUrlDefaultAppoint(pvCenter.theme, pvCenter.slug);
        let appointment = pvCenter.first_appoint_format;
        let lngLat = pvCenter.lng !== undefined && pvCenter.lat !== undefined ? getCenterLngLat(pvCenter) : null;

        if (lngLat !== null) {
            const marker = new mapboxgl.Marker().setLngLat(lngLat);
            const markerElement = marker.getElement();

            marker.setPopup(
                new mapboxgl.Popup().setHTML(`
                    <div class="popup-text">
                        <p class="title">${pvCenter.name} </p>
                        ${
                            pvCenter.address || pvCenter.postcode || pvCenter.city ? `
                            <p>${pvCenter.address ? `${pvCenter.address} ` : ""} ${
                                    pvCenter.postcode ? `${pvCenter.postcode} ` : ""
                                } ${pvCenter.city ? `${pvCenter.city} ` : ""}</p>
                                `
                                : ""
                        }
                        ${pvCenter.phone ? `<p>${pvCenter.phone}</p>` : ""}
                        <div class="appointment">
                        
                            ${isPartnerCentersTemplate ? `
                                <a class="underlined-link link-popup" rel="nofollow" href=${url_default_appoint ? `${url_default_appoint}` : ""}>
                                Prendre un rendez-vous
                                </a>` : `
                                ${appointment ? `
                                    <a class="underlined-link link-popup" rel="nofollow" href=${url_default_appoint ? `${url_default_appoint}` : ""}>
                                    Prendre un rendez-vous
                                    </a>` : `
                                    <a class="underlined-link link-popup disable" rel="nofollow">
                                    Prendre un rendez-vous
                                    </a>
                                `}
                            `}
                            
                        </div>
                    </div>
                `)
            );
            if (pvCenter.lng !== "" && pvCenter.lat !== "") {
                markerElement.addEventListener("click", () => {
                    removeClassSelectedForItemAndMarker();
                    markerElement.classList.add("mapboxgl-marker-selected");
                    let itemSelected = document.getElementsByClassName(
                        "element-" + pvCenter.id
                    )[0];
                    const itemsContainer = document.querySelector(".items");
                    const topPosition = itemSelected.offsetTop;

                    const relativeTopPosition = topPosition - itemsContainer.offsetTop;
                    document.querySelector(".items").scrollTop = relativeTopPosition;
                    itemSelected.classList.add("item-selected");
                    selectCenter(pvCenter, map);
                });
            }
            pvCenter.marker = marker;
            pvCenter.id = key;
            pvCentersById[key] = pvCenter;
        }
    });
    map.on("load", function () {
        map.setLayoutProperty("country-label", "text-field", ["get", "name_fr"]);
    });

    const {north, east, south, west} = getSelectedRegionCoordinates(
        selectedRegion
    );
    map.fitBounds([
        [east, north],
        [west, south],
    ]);

	if(currentCoord !== "none"){
	  map.setCenter(currentCoord).setZoom(10);
	}

        setMap(map);
        setFilteredPVCenters(pvCentersById);
    }, [appState]);

    React.useEffect(() => {
        if (!map) {
            return;
        }
        appState.centers.forEach((pvCenter) => {
            if (pvCenter.marker !== undefined) {
                if (filteredPVCenters[pvCenter.id]) {
                    if ( pvCenter.first_appoint === null ) {
                        pvCenter.marker.getElement().classList.add("marker-disable");
                    }
                    pvCenter.marker.addTo(map);
                } else {
                    pvCenter.marker.remove();
                }
            }

        });
    }, [filteredPVCenters]);

    //Load more
    const handleLoadMore = () => {
        if (currentPage <= chosenCenter.length) {
            let tabs = [];
            for (let i = currentPage; i < currentPage + maxCenter; i++) {
                if (chosenCenter[i]) {
                    tabs.push(chosenCenter[i])
                }
            }
            setCurrentPage(currentPage + maxCenter);
            setAppState({centers: [...appState.centers, ...tabs]});
        }
    }

    const getSelectedRegionCoordinates = (selectedRegion) => {
        return Object.keys(regions).reduce(
            (acc, key) => {
                const {label, value, north, east, west, south} = regions[key];
                if (value === selectedRegion) {
                    return {
                        north,
                        south,
                        east,
                        west,
                    };
                }

                return acc;
            },
            {
                north: 51.5,
                south: 41,
                east: 10,
                west: -5.8,
            }
        );
    };

    const handleChangeRegion = (event) => {
        setSelectedRegion(event.target.value);
        const centerFilteredByRegion = [];

        const {north, east, south, west} = getSelectedRegionCoordinates(
            event.target.value
        );

        for (let i = 0; i < items.length; i++) {
            if (items[i].region === event.target.value) {
                centerFilteredByRegion.push(items[i]);
            }
        }
        if (centerFilteredByRegion.length > 0){
            setCenterExist(true);
        }else{
            setCenterExist(false);
        }

        if (event.target.value !== "none") {
            setAppState({centers: centerFilteredByRegion});
        } else {
            setAppState({centers: items});
            setCenterExist(true);
        }

        // Fit map to region bounds, if no region selected fit to France
        map.fitBounds([
            [east, north],
            [west, south],
        ]);
    };

    const selectCenter = (center, map, togglePopup = false) => {
        if (center.lng != "" && center.lat != "") {
            if (selectedCenterId) {
                filteredPVCenters[selectedCenterId].marker.togglePopup();
            }
            setSelectedCenterId(center.id);
            if (togglePopup) {
                center.marker.togglePopup();
                removeClassSelectedForItemAndMarker();
                center.marker.getElement().classList.add("mapboxgl-marker-selected");
                document
                    .getElementsByClassName("element-" + center.id)[0]
                    .classList.add("item-selected");
            }
            if (map) {
                map.setCenter(getCenterLngLat(center)).setZoom(8);
            }
        }
    };

    const handDisplayMapMobile = () => {
        const map = document.querySelector(".mapboxgl-map");
        const text = document.querySelector(".show-map p");

        map.classList.toggle("show-part");

        if (text.innerText === "Afficher la carte") {
            text.innerText = "Masquer la carte";
        } else {
            text.innerText = "Afficher la carte";
        }
    };

    const getCenterLngLat = (center) => [center.lng, center.lat];

    function getUrlDefaultAppoint(theme, ...slug) {
        const wp_rdv_template = document.getElementById("mapbox").dataset.rdvTemplate;
        const partner_name    = document.getElementById("mapbox").dataset.namePartner;
        const partner_code    = document.getElementById("mapbox").dataset.codePartner;
        const motif_partner   = document.getElementById("mapbox").dataset.motifPartnerId;

        let defaultPattern = 0;

        //get the default patterns
        appPatterns.patterns.forEach((pattern) => {
            if (pattern.default === "true") {
                defaultPattern = pattern;
            }
        });
        let reason = "";
        let partner = "";

        if (defaultPattern.id !== undefined) {
            reason = "/?reason=" + defaultPattern.id;
        }

        if (isPartnerCentersTemplate && motif_partner) {
            reason = "/?reason=" + motif_partner;
        }

        if (partner_code) {
            partner = "&partenaire=" + partner_code;
        }
        if ( window.location.href.split('/')[3] === "point-vision-jo"
            || window.location.href.split('/')[3] === "paris-2024" )
        {
            if (slug && slug !== "") {
                if (isPartnerCentersTemplate) {
                    if (motif_partner) {
                        return 'https://pointvision.com/' + slug + '/' + wp_rdv_template + reason + partner;
                    }
                    return 'https://pointvision.com/' + slug + '/partenaire/'+ partner_name;
                }
                return 'https://pointvision.com/' + slug + '/' + wp_rdv_template + reason + partner;
            }
            return 'https://pointvision.com/' + wp_rdv_template + reason + partner;
        }
        else if (theme === "pv-centre") {
            if (slug && slug !== "") {
                if (isPartnerCentersTemplate) {
                    if (motif_partner) {
                        return window.location.origin.split('?')[0] + '/' + slug + '/' + wp_rdv_template + reason + partner;
                    }
                    return window.location.origin + '/' + slug + '/partenaire/'+ partner_name;
                }
                return window.location.origin.split('?')[0] + '/' + slug + '/' + wp_rdv_template + reason + partner;
            }
            if (isPartnerCentersTemplate) {
                if (motif_partner) {
                    return window.location.href.split('?')[0] + wp_rdv_template + reason + partner;
                }
                return window.location.origin + '/partenaire/'+ partner_name;
            }
            return window.location.href.split('?')[0] + wp_rdv_template + reason + partner;
        }
        else {
            if (slug && slug !== "") {
                if (isPartnerCentersTemplate) {
                    if (motif_partner) {
                        return window.location.origin.split('?')[0] + '/' + slug + '/' + wp_rdv_template + reason + partner;
                    }
                    return window.location.origin + '/' + slug + '/partenaire/'+ partner_name;
                }
                return window.location.origin.split('?')[0] + '/' + slug + '/';
            }
            if (isPartnerCentersTemplate) {
                if (motif_partner) {
                    return window.location.origin.split('?')[0] + '/' + wp_rdv_template + reason + partner;
                }
                return window.location.origin + '/partenaire/'+ partner_name;
            }
            return window.location.href.split('?')[0];
        }
    }

    function removeClassSelectedForItemAndMarker() {
        for (let item of document.getElementsByClassName("mapboxgl-marker")) {
            item.classList.remove("mapboxgl-marker-selected");
        }
        for (let item of document.getElementsByClassName("item")) {
            item.classList.remove("item-selected");
        }
        for (let item of document.getElementsByClassName("offer")) {
            item.classList.remove("item-selected");
        }
    }

    function displayFilters() {
        return (
            <div>
                <select value={selectedRegion} onChange={handleChangeRegion}>
                    <option value="none">Toutes les régions</option>
                    {regions.map(({value, label}, index) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    /*function to check witch item template display for map*/
    function displayItem(pvCenter) {
        let appointment = pvCenter.first_appoint_format;
        let url_default_appoint = getUrlDefaultAppoint(pvCenter.theme, pvCenter.slug);
        let expertClass = document.querySelector('body').classList.contains('expert');
        return (
            <div
                className={`item element-` + pvCenter.id}
                key={pvCenter.id}
                onClick={() => selectCenter(pvCenter, map, true)}
            >
                <div className="wrapper-top">
                    <div className="box-left">
                        <p className="title">{pvCenter.name}</p>
                        {(pvCenter.address || pvCenter.postcode || pvCenter.city) && (
                            <p>{pvCenter.address && <>{pvCenter.address + " "}</>}</p>
                        )}
                        {pvCenter.phone && (
                            <p className="tel">{pvCenter.phone}</p>
                        )}
                    </div>
                    {pvCenter.patterns && pvCenter.patterns.length && (!isPartnerCentersTemplate) && (
                        <div className="typology">
                            {pvCenter.patterns.map(function (t, idx) {
                                let reason = t.split(" - ");
                                return <a key={idx}>{reason[0]}</a>;
                            })}
                        </div>
                    )}
                </div>
                {isPartnerCentersTemplate && (

                    <div className="content-bottom partner-center-link">
                        <a className="underlined-link" rel="nofollow" href={url_default_appoint}>
                            Prendre un rendez-vous
                        </a>
                    </div>

                )}
                {(!isPartnerCentersTemplate) &&(
                    <div className="content-bottom">
                        <div className="calendar">
                            {(expertClass) && (
                                <figure>
                                    <img src="/app/themes/pv-centre/assets/images/expert/calendar-validate-expert.svg"/>
                                </figure>
                            )}
                            {(!expertClass) && (
                                <figure>
                                    <img src="/app/themes/pv-centre/assets/images/icon-calendar.svg"/>
                                </figure>
                            )}
                            <div className="content-calendar">
                                <p>Prochain rendez-vous : </p>
                                {appointment && (
                                    <p className="date-calendar" data-dates={appointment}>
                                        {appointment}
                                    </p>
                                )}
                                {(!appointment) && (
                                    <p className="date-calendar">
                                        Aucun rendez-vous
                                    </p>
                                )}
                            </div>
                        </div>
                        {appointment && (
                            <a className="underlined-link" rel="nofollow" href={url_default_appoint}>
                                Prendre un rendez-vous
                            </a>
                        )}
                        {(!appointment) && (
                            <a className="underlined-link disable" rel="nofollow">
                                Prendre un rendez-vous
                            </a>
                        )}
                    </div>
                )}
            </div>
        );
    }

    const handleChangeReason = (event) => {
        const reasonId = event.target.options[event.target.selectedIndex].dataset.id
        const value = event.target.value;
        const selectAge = document.getElementById("selectAge");

        setSelectedReason(value);
        setSelectedReasonId(reasonId);
        if (selectAge) {
            selectAge.disabled = event.target.options[event.target.selectedIndex].dataset.age !== "true";

            if (event.target.options[event.target.selectedIndex].dataset.age !== "true") {
                selectAge.value = "0";
            }
        }
    };

    function getDistanceCenter(centers,coordinates= null) {
        let distanceCenters = [];

        centers.map((item) => {
            if (item.lat && item.lng) {
                const temp = {
                    data: item,
                    distance: turf.distance(
                        [item.lng, item.lat],
                        [coordinates[0], coordinates[1]],
                        {units: 'kilometers'})
                }
                distanceCenters.push(temp)
            }
        })

        distanceCenters.sort(function (a, b) {
            return a.distance - b.distance;
        });

        return distanceCenters;
    }

    function setFilteredCenter(centers, param = null, coordinates= null) {
        let filteredCentres = [];
        let tab = [];
        let distanceCenters = [];
        let centerExist = false;

        if(coordinates){
            centerExist = true;
        }

        setCenterExist(centerExist)

        if (coordinates) {
            distanceCenters = getDistanceCenter(centers, coordinates);

            for (let i = 0; i < distanceCenters.length; i++) {
                let currentCenter = {};

                if (isContainPattern(distanceCenters[i], param)) {
                    currentCenter.id = distanceCenters[i]['data'].id;
                    currentCenter.id_alax = distanceCenters[i]['data'].id_alax;
                    currentCenter.slug = distanceCenters[i]['data'].slug;
                    currentCenter.theme = distanceCenters[i]['data'].theme;
                    currentCenter.name = distanceCenters[i]['data'].name;
                    currentCenter.patterns = distanceCenters[i]['data'].patterns;

                    currentCenter.lng = distanceCenters[i]['data'].lng;
                    currentCenter.lat = distanceCenters[i]['data'].lat;

                    currentCenter.address = distanceCenters[i]['data'].address;
                    currentCenter.phone = distanceCenters[i]['data'].phone;

                    if (distanceCenters[i]['data'].first_appoint) {
                        currentCenter.first_appoint = new Date(distanceCenters[i]['data'].first_appoint);
                        currentCenter.first_appoint_format = distanceCenters[i]['data'].first_appoint_format;
                    } else {
                        currentCenter.first_appoint = new Date("01/01/5000");
                        currentCenter.first_appoint_format = distanceCenters[i]['data'].first_appoint_format;
                    }

                    filteredCentres.push(currentCenter);
                }
            }

            if(filteredCentres.length > 0){
                setCenterExist(true);
            }
            else {
                setCenterExist(false);
            }

            for (let i = 0; i < filteredCentres.length; i++) {
                if (i === maxCenter) {
                    break;
                }
                tab.push(filteredCentres[i])
            }

            tab.sort(function (a, b) {
                return new Date(a.first_appoint) - new Date(b.first_appoint);
            });

            if (param.reason === "none" && param.search === "") {
                setChosenCenter(items);
                setAppState({centers: limitedCenter});
            }
            else {
                setChosenCenter(filteredCentres);
                setAppState({centers: tab});
            }
        }
        else{
            setAppState({centers: []});
        }
    }

    function isContainPattern(center, param) {
        let containPattern = false;

        if (param) {
            if (center['data'].patterns) {
                for (let i = 0; i < center['data'].patterns.length; i++) {
                    if (param.reason === center['data'].patterns[i] || param.reason === "none") {
                        containPattern = true;
                    }
                }
            }
        }
        return containPattern;
    }

    function handleSubmitFilter() {
        const reasonInput = document.getElementsByClassName("select-box");
        const addInput = document.getElementsByClassName("mapboxgl-ctrl-geocoder--input");

        //Reset maximun page var
        setCurrentPage(maxCenter);

        let param = {
            age: "0",
            reason: reasonInput[0].value,
            search: addInput[0].value,
        };

        let coordinates = getCoordinates(param.search);

        coordinates.then(value => {
            setCurrentCoord(value);
        });
    }

    function activeBtnFilter(type) {
        let btn_nearby = document.getElementsByClassName("btn-nearby-center");
        let btn_close_date = document.getElementsByClassName("btn-close-date");

        if (type === "nearby_dates") {
            btn_nearby[0].classList.add("active");
            btn_close_date[0].classList.remove("active");
        } else if (type === "nearby_centers") {
            btn_nearby[0].classList.remove("active");
            btn_close_date[0].classList.add("active");
        }
    }

    function filterByDistance() {
        activeBtnFilter("nearby_dates");
        handleSubmitFilter();
    }

    function filterByDate() {
        activeBtnFilter("nearby_centers");

        appState['centers'].sort(function (a, b) {
            if (a.first_appoint) {
                return new Date(a.first_appoint) - new Date(b.first_appoint);
            }
        });
    }

    /* function display search bar */
    function displaySearch() {
        return (
            <>
                <div className="group-input">
                    <select value={selectedReason} name="selectReason" className="select-box"
                            onChange={handleChangeReason}>
                        <option data-id="0" data-age="false" value="none">Choisir un motif de consultation</option>
                        {appPatterns['patterns'].map(({value, label, age_restriction, id}, index) => (
                            <option data-id={id} data-age={age_restriction} key={index} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="group-input" id="geo-search"></div>
                <div className="group-input" id="select-age">
                    <select name="selectAge" id="selectAge" className="select-box" disabled>
                        <option value="0">Filtrer l'âge</option>
                        {filterAge.map((index) => (
                            <option key={index} value={index}>
                                {index}
                            </option>
                        ))}
                    </select>
                </div>
                <button id="btnSearch" type="button" className="btn-search" onClick={handleSubmitFilter}>
                    Rechercher
                </button>
            </>
        );
    }

    /* function display filter bar */
    function displayFilterBar() {
        return (
            <>
                <p className="title-h1">Filtrer par :</p>
                <button type="button" className="btn-filter btn-close-date active" onClick={filterByDate}>
                    Dates les plus proches
                </button>
                <button type="button" className="btn-filter btn-nearby-center" onClick={filterByDistance}>
                    Centres les plus proches
                </button>
            </>
        );
    }
    return (
        <>
            {isRDVCentersTemplate && (
                <>
                    <div className="search-container rdv-search">{displaySearch()}</div>
                </>
            )}
            <div className={`map-container ${isRDVCentersTemplate ? 'style-2' : ''}`}>
                {isRDVCentersTemplate && <div className="filter mobile">{displayFilters()}</div>}
                {/* <a className="map-display">Afficher la carte</a> */}
                <div ref={mapContainer}/>
                <div className="right-column">
                    <div className="filter">{displayFilters()}</div>
                    <div
                        className="show-map"
                        onClick={handDisplayMapMobile}
                        onTouchStart={handDisplayMapMobile}
                    >
                        <p>Afficher la carte</p>
                    </div>
                    {isRDVCentersTemplate}
                    <div className="items">
                        {!centerExist &&
                        <div className="empty-centre empty">
                            <p>Aucun centre ne correspond à votre recherche.</p>
                        </div>
                        }
                        {centerExist &&
                        <div className="empty-centre empty" hidden={ filteredPVCenters.length !== 0 }>
                            <div className="loader"></div>
                        </div>
                        }
                        {Object.keys(filteredPVCenters).map((key) => {
                            return displayItem(filteredPVCenters[key]);
                        })}
                        {isRDVCentersTemplate && currentPage < chosenCenter.length && centerExist &&
                        <button className="loadmore" onClick={handleLoadMore}>Voir plus</button>
                        }
                    </div>
                </div>
            </div>
            {isRDVCentersTemplate && <button type="button" className="go-back" onClick={() => history.back()}>
                Retour
            </button>}
        </>
    );
}

Map.propTypes = {
    centers: PropTypes.array,
};
export default Map;
